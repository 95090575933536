<template>
  <div class="an-li">
    <div class="image">
      <van-image
          class="image-div"
          height="88"
          width="121"
          :src="item.image[0].url"
      />
    </div>
    <div class="info" @click="showInfo">
      <div class="van-ellipsis name">
        {{ item.name.length > 0 ? item.name : '暂无数据' }}
      </div>
      <div class="van-two-ellipsis g-name van_content">
        {{ item.jian_jie ? item.jian_jie : '暂无数据' }}
      </div>
      <div class="van-ellipsis di-zhi g-name">
        <div class="van_list_item">
          <van-icon name="clock-o"/>
          <span style="margin-left:5px"> {{ item.create_time }}</span>
        </div>
        <div>
          <a href="javascript:">
            <i class="iconfont icon-xiangyoujiaohuan"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XEUtils from "xe-utils";
import {useRouter} from "vue-router";

export default {
  name: 'NewList',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props) {
    const router = useRouter()

    function showInfo() {
      let to = {
        name: 'PlatformNewsDetail', query: {
          id: props.item.id,
          ran_id: XEUtils.uniqueId('page_')
        }
      }
      router.push(to)
    }

    return {
      showInfo
    }
  }
}
</script>


<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.an-li {
  display: flex;
  margin: 10px;
  padding: 8px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px #ecdddd;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-around;
  align-items: flex-start;

  div {
    margin: 1px 0;
  }

  .name {
    font-size: 16px;
    font-weight: 700;
  }

  .type {
    padding: 5px 0;
  }

  .van-two-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .g-name {
    color: @gray-6;
  }

  .van_content {
    max-height: 40px;
  }

  .di-zhi {
    color: @gray-6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;

    .van_list_item {
      display: flex;
      align-items: center;
    }
  }

  .image {
    width: 125px;
  }

  .info {
    flex: 1;
    padding: 0 10px;
    width: calc(100% - 125px);
  }
}

</style>
