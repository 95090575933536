<template>
  <van-tabs v-model:active="pageName" @change="pageChange">
    <van-tab title="平台介绍" name="PlatformPlatformProduce"></van-tab>
    <van-tab title="专家库" name="PlatformSpecialistLibrary"></van-tab>
    <van-tab title="公司新闻" name="PlatformCompanyNews"></van-tab>
    <van-tab title="政策资讯" name="zheng_fu_zi_xun"></van-tab>
    <van-tab title="常见问题" name="PlatformGeneralProblem"></van-tab>
  </van-tabs>
</template>

<script>
export default {
  name: 'ZiXunHeader',
  mixins: [],
  data(){
    return {
      pageName:this.$route.name
    }
  },
  mounted() {
  },
  methods: {
    //切换页面
    pageChange(page) {
      this.$router.push({name: page})
    },
  },
  activated(){
    this.pageName = this.$route.name
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>

</style>
